.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.MuiFormControl-root {
  justify-content: center !important;
}

.MuiListItemIcon-root {
  width: 90%;
}

.MuiTypography-root {
  font-family: Kanit !important;
}

.overlay-custom-class-name {
  z-index: 1055 !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.custom-fileUploader {
  width: 100% !important;
  max-width: none !important;
  height: 90px !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-iframe {
  width: 100% !important;
  height: 85vh !important;
  border: none !important;
}

.custom-body {
  width: 100% !important;
  text-align: center;
  min-height: 70vh !important;
}

.report-container {
  height: 75vh;
  margin: 8px auto;
  width: 100%;
}

iframe {
  border: none;
}

.MuiDataGrid-columnHeaderTitle {
  opacity: 0.9;
  font-weight: bold !important;
}

.link-default {
  cursor: pointer !important;
  color: #474E93 !important;
}

.link-default:hover {
  color: #3D3BF3 !important;
}

/* Custom styles for the drag-drop container */
.react-drag-drop-files__container {
  background-color: #f0f0f0 !important; /* Change background color */
  border-radius: 10px; /* Add rounded corners */
  border: 2px dashed #3498db; /* Add a dashed border */
  padding: 20px; /* Add padding */
  transition: background-color 0.3s ease; /* Smooth background change */
}

/* Custom style when dragging over the area */
.react-drag-drop-files__container.dragging {
  background-color: #e6f7ff; /* Change color on drag */
}

/* Custom style for the message text */
.react-drag-drop-files__message {
  color: #333; /* Change message color */
  font-size: 18px; /* Change font size */
  font-weight: bold; /* Make the font bold */
  text-align: center; /* Center align the text */
}

/* Add a hover effect to the drop area */
.react-drag-drop-files__container:hover {
  border-color: #2980b9;
}

/* global class custom components */
.page-title {
  font-size: 20px !important;
  font-weight: bold !important;
}

.tl {
  text-align: left !important;
}

.tr {
  text-align: right !important;
}

.tc {
  text-align: center !important;
}

.opa-8 {
  opacity: 0.8 !important;
}

.opa-6 {
  opacity: 0.6 !important;
}

.opa-4 {
  opacity: 0.4 !important;
}

.opa-2 {
  opacity: 0.2 !important;
}

.dv-line {
  background-color: #333333;
  width: 100%;
  height: 1px;
}